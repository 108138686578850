.fk-post-container {
  display: grid;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
  row-gap: 1.5em;
  border-radius: 16px;
  box-shadow: 0 7px 44px 0 rgba(0, 0, 0, 0.13);
  background-color: #f3f3f3;
  cursor: default;
  animation: popup 0.5s; }
  .fk-post-container .fk-post-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.25);
    max-height: 420px;
    display: flex;
    flex-flow: column;
    justify-self: center; }
    .fk-post-container .fk-post-image .fk-image-slider {
      width: 100%;
      height: 100%; }
      .fk-post-container .fk-post-image .fk-image-slider .slick-track,
      .fk-post-container .fk-post-image .fk-image-slider .slick-list {
        height: 100%; }
      .fk-post-container .fk-post-image .fk-image-slider .slick-slide div {
        height: 100%; }
      .fk-post-container .fk-post-image .fk-image-slider .slick-dots {
        bottom: 10px; }
        .fk-post-container .fk-post-image .fk-image-slider .slick-dots li button:before {
          color: #fff; }
      .fk-post-container .fk-post-image .fk-image-slider .slick-arrow {
        z-index: 1; }
        .fk-post-container .fk-post-image .fk-image-slider .slick-arrow.slick-prev {
          left: 10px; }
        .fk-post-container .fk-post-image .fk-image-slider .slick-arrow.slick-next {
          right: 10px; }
      .fk-post-container .fk-post-image .fk-image-slider .fk-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
        box-sizing: border-box;
        border: solid 5px #f4f4f4;
        background-color: #fff;
        border-radius: 5px; }
  .fk-post-container .fk-post-video {
    display: grid;
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-height: 420px;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.6)); }
    .fk-post-container .fk-post-video .fk-video {
      width: 100%;
      height: 100%;
      overflow: hidden;
      object-fit: cover;
      box-sizing: border-box;
      border: 0.2em solid #ffffff;
      border-radius: 5px; }
  .fk-post-container .fk-post-content {
    display: grid;
    row-gap: 1em;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 1.2;
    color: #0d274d;
    font-size: 10px; }
    .fk-post-container .fk-post-content .fk-text {
      font-size: 1.6em;
      color: #000; }
    .fk-post-container .fk-post-content .fk-tags {
      font-size: 1.6em;
      font-weight: bold;
      color: #0076d5; }
    .fk-post-container .fk-post-content .fk-user-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 1em;
      width: 100%;
      height: 100%; }
      .fk-post-container .fk-post-content .fk-user-details .fk-user-image {
        display: grid;
        align-items: center;
        justify-content: center;
        width: 4.5em;
        height: 4.5em;
        border: solid 1px #1485ee;
        background-color: #f5f5f5;
        border-radius: 50%; }
        .fk-post-container .fk-post-content .fk-user-details .fk-user-image .fk-image {
          width: 4em;
          height: 4em;
          object-fit: cover;
          border-radius: 50%;
          box-sizing: border-box; }
      .fk-post-container .fk-post-content .fk-user-details .fk-user-name {
        font-size: 1.4em;
        line-height: 1.43;
        color: #0076d5;
        flex: 1; }
      .fk-post-container .fk-post-content .fk-user-details .fk-user-likes {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding-right: 7px; }
        .fk-post-container .fk-post-content .fk-user-details .fk-user-likes .fk-heart-icon {
          cursor: pointer; }
          .fk-post-container .fk-post-content .fk-user-details .fk-user-likes .fk-heart-icon svg path {
            stroke-width: 10px;
            stroke: #0076d5;
            fill: none; }
        .fk-post-container .fk-post-content .fk-user-details .fk-user-likes .fk-liked-heart-icon {
          cursor: pointer; }
          .fk-post-container .fk-post-content .fk-user-details .fk-user-likes .fk-liked-heart-icon svg path {
            fill: #0076d5; }
        .fk-post-container .fk-post-content .fk-user-details .fk-user-likes .fk-likes-count {
          font-size: 1.4em;
          font-weight: bold;
          color: #0076d5; }

@keyframes popup {
  from {
    opacity: 0;
    transform: scale(0); }
  to {
    opacity: 1;
    transform: scale(1); } }

@media (max-width: 768px) {
  .fk-post-container {
    max-height: max-content !important; } }
