.fk-filter {
  width: 100%;
  min-height: 20px;
  display: flex;
  gap: 40px;
  padding: 0 0 10px;
  flex-wrap: wrap; }
  .fk-filter .fk-filter-item {
    border: solid 2px #1485ee;
    border-radius: 20px;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    place-items: center;
    cursor: pointer;
    color: #1485ee; }
    .fk-filter .fk-filter-item.fk-selected {
      background-color: #1485ee;
      color: #fff; }

.fk-filter-mobile {
  gap: 10px; }
  .fk-filter-mobile .fk-filter-item {
    border: solid 1px #1485ee;
    font-size: 13px; }
