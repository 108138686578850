.fk-video-player {
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 10px;
  overflow: hidden;
  max-height: inherit; }
  .fk-video-player .fk-video-player-inner {
    box-sizing: border-box;
    border: 5px solid #ffffff;
    border-radius: 14px;
    width: 100%;
    height: inherit;
    max-height: inherit;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: black;
    display: flex; }
    .fk-video-player .fk-video-player-inner .fk-loader-container {
      backdrop-filter: none; }
    .fk-video-player .fk-video-player-inner .fk-video-thumbnail {
      position: absolute;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
      background-position: center; }
      .fk-video-player .fk-video-player-inner .fk-video-thumbnail .fk-video-play-icon {
        width: 40px;
        height: 40px;
        background-color: #ffffff00;
        border-radius: 100%; }
    .fk-video-player .fk-video-player-inner .fk-video-overlay {
      position: absolute;
      border-radius: 10px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: black;
      opacity: 0;
      z-index: 2; }
      .fk-video-player .fk-video-player-inner .fk-video-overlay .fk-video-pause-icon {
        width: 40px;
        height: 40px; }
      .fk-video-player .fk-video-player-inner .fk-video-overlay:hover {
        opacity: 0.3; }
    .fk-video-player .fk-video-player-inner .fk-video-player-container {
      width: 100%;
      height: 100%;
      max-width: 100%;
      overflow: hidden;
      margin: auto; }
      .fk-video-player .fk-video-player-inner .fk-video-player-container .fk-video-player-screen {
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover; }
  .fk-video-player .fk-video-control {
    margin: -15px 10px 10px 10px; }
    .fk-video-player .fk-video-control .fk-video-seek-bar {
      width: 100%;
      height: 6px;
      cursor: pointer; }
    .fk-video-player .fk-video-control .fk-video-timestamp {
      display: flex;
      justify-content: space-between;
      font-size: 10px;
      color: #0d274d;
      padding: 5px 1px 1px 1px; }
    .fk-video-player .fk-video-control progress.fk-video-seek-bar {
      background-color: #f5f5f5;
      border-radius: 5px; }
      .fk-video-player .fk-video-control progress.fk-video-seek-bar::-webkit-progress-bar {
        background-color: #f5f5f5;
        border-radius: 5px; }
      .fk-video-player .fk-video-control progress.fk-video-seek-bar::-webkit-progress-value {
        background-color: #aaaaaa;
        border-radius: 5px; }
      .fk-video-player .fk-video-control progress.fk-video-seek-bar::-moz-progress-bar {
        background-color: #aaaaaa;
        border-radius: 5px; }
