@font-face {
  font-family: 'CiscoSansTT';
  src: url("../../assets//CiscoSansTTRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.fk-gallery-view-container-mobile {
  font-family: 'CiscoSansTT';
  padding: 0;
  background: #f5f5f5;
  box-sizing: border-box; }
  .fk-gallery-view-container-mobile .fk-story-wrapper {
    display: grid; }
    .fk-gallery-view-container-mobile .fk-story-wrapper .fk-story-wrapper-inner {
      overflow: auto;
      border-radius: 20px; }
      .fk-gallery-view-container-mobile .fk-story-wrapper .fk-story-wrapper-inner .fk-story {
        display: flex;
        justify-content: center;
        margin: 20px; }

.fk-scroll-loader {
  width: 100%;
  height: 40px;
  position: relative; }
  .fk-scroll-loader .fk-loader-container {
    backdrop-filter: none; }
    .fk-scroll-loader .fk-loader-container .fk-loader {
      width: 40px;
      height: 40px; }
