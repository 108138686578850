.fk-loader-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px); }
  @supports not ((backdrop-filter: blur(10px)) or (-webkit-backdrop-filter: blur(10px))) {
    .fk-loader-container {
      background-color: rgba(245, 245, 245, 0.9); } }
  .fk-loader-container .fk-loader {
    width: 100px;
    height: 100px; }
    .fk-loader-container .fk-loader .fk-loader-path {
      stroke: transparent;
      fill: #0d274d; }
