@font-face {
  font-family: 'CiscoSansTT';
  src: url("../assets//CiscoSansTTRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.cisco-impact {
  font-family: 'CiscoSansTT';
  width: 100%;
  min-height: 740px;
  height: 740px;
  max-height: 740px;
  display: flex;
  flex-direction: column; }
  .cisco-impact .fk-gallery-container {
    width: 100%;
    min-height: 740px;
    height: 740px;
    max-height: 740px;
    display: flex;
    flex-direction: column;
    position: relative; }
    .cisco-impact .fk-gallery-container .fk-footer-overlay {
      width: 100%;
      height: 100px;
      left: 0;
      background-image: linear-gradient(to bottom, rgba(245, 245, 245, 0), rgba(245, 245, 245, 0.7) 50%, #f5f5f5 90%);
      position: absolute;
      bottom: 0;
      display: flex; }
