.fk-gallery-unauthorized {
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
  z-index: 4;
  color: #0d274d;
  display: grid;
  justify-content: center;
  background-color: #f5f5f5;
  text-align: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box; }
  .fk-gallery-unauthorized .fk-unauthorized-content .fk-unauthorized-heading {
    font-size: 32px;
    font-weight: bold; }
  .fk-gallery-unauthorized .fk-unauthorized-content .fk-unauthorized-text {
    font-size: 16px; }

.fk-gallery-no-content {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  color: #0d274d;
  display: grid;
  justify-content: center;
  background-color: #f5f5f5;
  text-align: center;
  align-items: center; }
  .fk-gallery-no-content .fk-no-content-heading {
    font-size: 32px;
    font-weight: bold; }
  .fk-gallery-no-content .fk-no-content-text {
    font-size: 16px; }
