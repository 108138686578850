.fk-filters-dropdown-wrapper {
  position: absolute;
  width: 100%;
  height: auto;
  top: 88px;
  left: 0;
  max-height: calc(100% - 88px);
  background-color: #fff;
  z-index: 20; }
  .fk-filters-dropdown-wrapper .fk-filters-dropdown {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box; }
    .fk-filters-dropdown-wrapper .fk-filters-dropdown .fk-filters-dropdown-filter {
      padding: 0 20px; }
      .fk-filters-dropdown-wrapper .fk-filters-dropdown .fk-filters-dropdown-filter:first-child {
        margin-bottom: 69px; }
      .fk-filters-dropdown-wrapper .fk-filters-dropdown .fk-filters-dropdown-filter .fk-filter-label {
        margin: 10px 0; }
    .fk-filters-dropdown-wrapper .fk-filters-dropdown .fk-filters-dropdown-filter-border {
      border-top: 1px solid #cecece;
      margin-top: 15px;
      padding-top: 10px; }
    .fk-filters-dropdown-wrapper .fk-filters-dropdown .fk-filters-reset {
      position: absolute;
      top: 20px;
      right: 30px;
      cursor: pointer;
      color: #1485ee; }
    .fk-filters-dropdown-wrapper .fk-filters-dropdown .fk-filters-confirm-btn {
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: -35px;
      padding-right: 20px; }
      .fk-filters-dropdown-wrapper .fk-filters-dropdown .fk-filters-confirm-btn .fk-filters-confirm {
        background-color: #0d274d;
        cursor: pointer;
        color: #fff;
        width: max-content;
        padding: 8px 20px;
        border-radius: 3px;
        background-color: #1485ee;
        color: #fff; }

.fk-filters-dropdown-wrapper-mobile .fk-filters-dropdown-filter {
  padding: 0 !important; }

.fk-filters-dropdown-wrapper-mobile .fk-filters-reset {
  font-size: 11px;
  top: 20px !important;
  right: 10px !important; }

.fk-filters-dropdown-wrapper-mobile .fk-filters-confirm-btn {
  top: 0 !important;
  margin-top: 4px;
  padding-right: 0 !important; }
  .fk-filters-dropdown-wrapper-mobile .fk-filters-confirm-btn .fk-filters-confirm {
    padding: 5px 12px !important;
    font-size: 11px; }
