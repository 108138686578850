@keyframes popUp-gallery-scroll {
  0% { }
  70% {
    transform: scale(1.1);
    opacity: 0.8;
    animation-timing-function: ease-out; }
  100% {
    transform: scale(1);
    opacity: 1; } }

.gallery-scroll-parent-container {
  background-color: #f3f3f3;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .gallery-scroll-parent-container .header {
    position: fixed;
    z-index: 10;
    display: flex;
    height: 125px;
    max-width: 1240px;
    width: 100%;
    align-items: center;
    background-color: #011528;
    padding: 0px; }
    .gallery-scroll-parent-container .header .back {
      display: flex;
      flex-grow: 0;
      text-align: center;
      margin-left: 10px;
      cursor: pointer; }
      .gallery-scroll-parent-container .header .back .icon-fk {
        display: none; }
      .gallery-scroll-parent-container .header .back .back-label {
        text-decoration: underline;
        font-size: 1.15em;
        font-family: CiscoSansTT; }
    .gallery-scroll-parent-container .header .title {
      font-size: 3.75em;
      font-family: CiscoSansThin;
      text-align: center;
      flex-grow: 100; }
    .gallery-scroll-parent-container .header .action {
      font-family: CiscoSansBold;
      font-size: 1.15em;
      background-color: #00bceb;
      padding: 10px 30px 8px 30px;
      border-radius: 25px;
      flex-grow: 0;
      text-align: center;
      margin-right: 10px;
      cursor: pointer; }
  .gallery-scroll-parent-container .scroll-to-top {
    background-color: #0084ff;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    position: absolute;
    bottom: 20px;
    right: 10px;
    text-align: center;
    line-height: 2;
    cursor: pointer; }
    .gallery-scroll-parent-container .scroll-to-top.icon-fk-up-1 {
      z-index: 1000;
      color: #ffffff;
      font-size: 1.2em; }
      .gallery-scroll-parent-container .scroll-to-top.icon-fk-up-1.hide {
        display: none; }
    .gallery-scroll-parent-container .scroll-to-top.hide {
      display: none; }
  .gallery-scroll-parent-container.fade-in .gallery-scroll-container .gallery-scroll-card {
    transform-style: preserve-3d;
    transform: scale(0.4);
    animation: popUp-gallery-scroll 0.8s ease-in forwards; }
  .gallery-scroll-parent-container.fade-out .gallery-scroll-container .gallery-scroll-card {
    transform-style: preserve-3d; }

.gallery-scroll-container {
  max-height: 940px; }
  .gallery-scroll-container .gallery-scroll-title {
    display: none; }
  .gallery-scroll-container .gallery-scroll-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .gallery-scroll-container .gallery-scroll-cards .gallery-author {
      display: none; }
    .gallery-scroll-container .gallery-scroll-cards .gallery-card {
      margin: 1px;
      width: 240px;
      height: 235px;
      overflow: hidden; }
      .gallery-scroll-container .gallery-scroll-cards .gallery-card .gallery-details {
        display: none; }
      .gallery-scroll-container .gallery-scroll-cards .gallery-card .gallery-src {
        font-size: 1em;
        z-index: 9; }
        .gallery-scroll-container .gallery-scroll-cards .gallery-card .gallery-src.src-twitter {
          background-color: #ffffff;
          box-shadow: none;
          text-shadow: none;
          width: 50px;
          height: 50px;
          border-radius: 50px;
          position: absolute;
          top: -20px;
          left: -20px; }
          .gallery-scroll-container .gallery-scroll-cards .gallery-card .gallery-src.src-twitter .icon-fk-twitter-1 {
            color: #00bceb;
            position: relative;
            top: 25px;
            left: 20px; }
        .gallery-scroll-container .gallery-scroll-cards .gallery-card .gallery-src.src-instagram {
          background-color: #ffffff;
          box-shadow: none;
          text-shadow: none;
          width: 50px;
          height: 50px;
          border-radius: 50px;
          position: absolute;
          top: -20px;
          left: -20px; }
          .gallery-scroll-container .gallery-scroll-cards .gallery-card .gallery-src.src-instagram .icon-fk-instagram-1 {
            color: #bc1888;
            position: relative;
            top: 25px;
            left: 20px; }
      .gallery-scroll-container .gallery-scroll-cards .gallery-card:hover .gallery-details {
        display: block; }
        .gallery-scroll-container .gallery-scroll-cards .gallery-card:hover .gallery-details .gallery-msg {
          display: none; }
        .gallery-scroll-container .gallery-scroll-cards .gallery-card:hover .gallery-details .gallery-stats {
          width: 85%;
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          top: 50%;
          right: 10%;
          margin-top: 10%;
          margin-left: 0; }
          .gallery-scroll-container .gallery-scroll-cards .gallery-card:hover .gallery-details .gallery-stats .reply {
            padding-right: 10px; }
          .gallery-scroll-container .gallery-scroll-cards .gallery-card:hover .gallery-details .gallery-stats .retweet {
            padding-right: 10px; }
