.fk-gallery-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9;
  backdrop-filter: blur(50px);
  color: #0d274d;
  display: grid;
  justify-content: center;
  text-align: center;
  align-items: center; }
  @supports not ((backdrop-filter: blur(50px)) or (-webkit-backdrop-filter: blur(50px))) {
    .fk-gallery-overlay {
      background-color: rgba(245, 245, 245, 0.9); } }
  .fk-gallery-overlay .fk-content .fk-heading {
    font-size: 32px;
    font-weight: bold;
    -webkit-text-stroke-width: 0.2px; }
  .fk-gallery-overlay .fk-content .fk-text {
    font-size: 16px;
    -webkit-text-stroke-width: 0.2px; }
  .fk-gallery-overlay .fk-content .fk-icons {
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 100px;
    padding: 40px;
    color: #0d274d;
    font-weight: bold; }
    .fk-gallery-overlay .fk-content .fk-icons.fk-grid {
      grid-template-columns: auto auto auto; }
    .fk-gallery-overlay .fk-content .fk-icons .fk-mouse-scroll-icon {
      align-self: end;
      -webkit-text-stroke-width: 0.2px; }
    .fk-gallery-overlay .fk-content .fk-icons .fk-key-scroll-icon {
      align-self: end;
      -webkit-text-stroke-width: 0.2px; }
  .fk-gallery-overlay .fk-content .fk-action {
    display: grid;
    justify-content: center; }
    .fk-gallery-overlay .fk-content .fk-action .fk-button {
      display: grid;
      align-items: center;
      justify-content: center;
      background-color: #1a3d69;
      background-image: linear-gradient(90deg, #0d274d, #1e4471);
      box-shadow: 14px 13px 25px 1px rgba(0, 0, 0, 0.33);
      width: 250px;
      min-height: 40px;
      font-size: 14px;
      line-height: 1.33;
      color: #f5f5f5;
      border: 4px solid #f5f5f5;
      border-radius: 24px;
      cursor: pointer; }
