@font-face {
  font-family: "fontello";
  src: url("./fontello.eot?27577804");
  src: url("./fontello.eot?27577804#iefix") format("embedded-opentype"),
    url("./fontello.woff2?27577804") format("woff2"),
    url("./fontello.woff?27577804") format("woff"),
    url("./fontello.ttf?27577804") format("truetype"),
    url("./fontello.svg?27577804#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?27577804#fontello') format('svg');
  }
}
*/
[class^="icon-fk"]:before,
[class*=" icon-fk"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-fk-reply-all:before {
  content: "\e800";
} /* '' */
.icon-fk-user:before {
  content: "\e801";
} /* '' */
.icon-fk-heart-empty:before {
  content: "\e802";
} /* '' */
.icon-fk-retweet:before {
  content: "\e803";
} /* '' */
.icon-fk-popup:before {
  content: "\e804";
} /* '' */
.icon-fk-twitter-1:before {
  content: "\e805";
} /* '' */
.icon-fk-instagram-1:before {
  content: "\e806";
} /* '' */
.icon-fk-up-1:before {
  content: "\e807";
} /* '' */
.icon-fk-left-open:before {
  content: "\e808";
} /* '' */
.icon-fk-right-open:before {
  content: "\e809";
} /* '' */
.icon-fk-left-dir:before {
  content: "\e80a";
} /* '' */
.icon-fk-heart-empty-1:before {
  content: "\e80b";
} /* '' */
.icon-fk-up:before {
  content: "\e812";
} /* '' */
.icon-fk-reply:before {
  content: "\f112";
} /* '' */
.icon-fk-youtube:before {
  content: "\f315";
} /* '' */
