@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");
.home-container {
  font-family: "Open Sans", "sans-serif";
  background-color: #eef2f6;
  max-height: 800px;
  max-width: 1280px;
  width: 100%;
  height: 100%;
  text-align: center; }
  .home-container .title-1 {
    color: #0d274d;
    color: #797979;
    font-weight: 600;
    font-size: 2em;
    text-align: center;
    width: 100%;
    padding-top: 1.5%; }
  .home-container .title-2 {
    color: #179fff;
    font-size: 1.8em;
    padding-top: 3%; }
  .home-container .title-hashtag {
    display: flex;
    justify-content: space-evenly; }
    .home-container .title-hashtag .hashtag {
      color: #0d274d;
      font-size: 1.8em;
      font-weight: 600;
      padding-top: 1%; }
    .home-container .title-hashtag .photobooth {
      color: #0d274d;
      font-size: 1.8em;
      font-weight: 600;
      padding-top: 1%; }
  .home-container .content {
    display: flex;
    justify-content: center; }
    .home-container .content .experience {
      background-color: #eef2f6;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0; }
      .home-container .content .experience .exp-container {
        display: flex;
        justify-content: center; }
        .home-container .content .experience .exp-container .block {
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #f3f3f3; }
      .home-container .content .experience .selection-container {
        padding: 1vw;
        margin-left: 5vw;
        margin-right: 5vw; }
        .home-container .content .experience .selection-container .selection {
          border-radius: 1em;
          border: solid 2px #e4e4e4;
          background-color: #ffffff; }
          .home-container .content .experience .selection-container .selection .top {
            width: 350px;
            height: 240px;
            background-color: #179fff;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-right-radius: 0.8em;
            border-top-left-radius: 0.8em;
            border-bottom-right-radius: 0.8em;
            border-bottom-left-radius: 0.8em; }
            .home-container .content .experience .selection-container .selection .top .imgalt {
              display: none; }
            .home-container .content .experience .selection-container .selection .top img {
              height: 75px; }
            .home-container .content .experience .selection-container .selection .top.highlights img {
              height: 85px; }
          .home-container .content .experience .selection-container .selection .below {
            width: 350px;
            height: 130px;
            text-align: center;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center; }
            .home-container .content .experience .selection-container .selection .below .selection-title {
              color: #0d274d;
              font-size: 1.8em; }
            .home-container .content .experience .selection-container .selection .below .selection-tag {
              color: #828282;
              font-size: 1em; }
      .home-container .content .experience .tagline {
        width: 350px;
        margin-left: 2vw;
        margin-right: 1vw;
        color: #0d274d;
        padding: 1vw;
        padding-left: 1vw;
        padding-right: 1vw;
        font-family: "Open Sans", sans-serif;
        font-size: 1em; }
        .home-container .content .experience .tagline p {
          margin-block-start: 0.1em;
          margin-block-end: 0.1em; }

@media (max-width: 320px) {
  .home-container .content .experience {
    padding: 1px; }
    .home-container .content .experience .exp-container .block .selection-container {
      padding: 1px;
      margin-left: 1px;
      margin-right: 1px; }
      .home-container .content .experience .exp-container .block .selection-container .selection .top {
        width: 250px;
        height: 140px; }
        .home-container .content .experience .exp-container .block .selection-container .selection .top img {
          height: 65px; }
      .home-container .content .experience .exp-container .block .selection-container .selection .below {
        width: 250px;
        height: 100px; }
        .home-container .content .experience .exp-container .block .selection-container .selection .below .selection-title {
          font-size: 1.5em; }
    .home-container .content .experience .exp-container .block .tagline {
      font-size: 0.75em;
      max-width: 250px; } }

@media (min-width: 320px) and (max-width: 480px) {
  .home-container .content .experience {
    width: 100%;
    padding: 1px; }
    .home-container .content .experience .exp-container {
      width: 100%; }
      .home-container .content .experience .exp-container .block {
        width: 95%; }
        .home-container .content .experience .exp-container .block .selection-container {
          width: 100%;
          padding: 1px;
          margin-left: 1px;
          margin-right: 1px; }
          .home-container .content .experience .exp-container .block .selection-container .selection {
            width: 100%; }
            .home-container .content .experience .exp-container .block .selection-container .selection .top {
              width: 100%;
              height: 140px; }
              .home-container .content .experience .exp-container .block .selection-container .selection .top img {
                height: 65px; }
            .home-container .content .experience .exp-container .block .selection-container .selection .below {
              width: 100%;
              height: 100px; }
              .home-container .content .experience .exp-container .block .selection-container .selection .below .selection-title {
                font-size: 1.5em; }
        .home-container .content .experience .exp-container .block .tagline {
          font-size: 0.75em;
          max-width: 300px; } }
