.gallery-card {
  width: 300px;
  height: 300px;
  max-height: 400px;
  background-size: cover;
  background-position: center center;
  position: relative;
  color: white;
  border: 1px solid #eee;
  cursor: pointer; }
  .gallery-card .gallery-src {
    font-size: 1.5em;
    z-index: 100;
    position: absolute;
    top: 4px;
    left: 2px;
    text-shadow: 0px 0px 18px #000000; }
  .gallery-card .gallery-details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .gallery-card .gallery-details .gallery-msg {
      padding-top: 10px;
      line-height: 20px;
      margin-left: 50px;
      font-family: Roboto; }
    .gallery-card .gallery-details .gallery-stats {
      margin-top: 10%;
      margin-left: 50%; }
    .gallery-card .gallery-details .view-post {
      width: 100%;
      height: 50px;
      position: absolute;
      text-align: center;
      bottom: 25px; }
  .gallery-card .gallery-stats span {
    margin: 0px 15px 0px 5px; }

.gallery-author {
  background-color: #ebebeb;
  position: relative;
  width: 99%;
  height: 40px;
  margin-left: 1px;
  margin-top: -1px; }
  .gallery-author .gallery-avatar {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 100px;
    position: absolute;
    top: 5px;
    left: 15px; }
  .gallery-author .gallery-name {
    font-family: Roboto;
    color: #222222;
    position: absolute;
    top: 5px;
    left: 65px;
    font-size: 0.8em;
    font-weight: 400; }
    .gallery-author .gallery-name .gallery-verified {
      display: none;
      width: 15px;
      vertical-align: text-bottom;
      padding: 0 5px; }
  .gallery-author .gallery-handle {
    font-family: Roboto;
    font-weight: 300;
    position: absolute;
    top: 20px;
    left: 65px;
    font-size: 0.6em; }
  .gallery-author .gallery-created-at {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 0.6em;
    color: #909090;
    font-weight: 300; }
