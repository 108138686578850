.cardv2 {
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 7px 43px 0 rgba(77, 77, 77, 0.11);
  background-color: #ffffff;
  margin: 10px; }
  .cardv2 .image {
    width: 300px;
    height: 400px;
    max-height: 400px;
    background-size: cover;
    background-position: center center; }
    .cardv2 .image .instagram-pic img,
    .cardv2 .image .twitter-pic img {
      max-width: 300px;
      border-top-right-radius: 8px;
      border-top-left-radius: 8px; }
    .cardv2 .image .source {
      position: absolute;
      top: 10px;
      right: 10px; }
      .cardv2 .image .source .src-twitter {
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background-color: #ffffff; }
        .cardv2 .image .source .src-twitter .icon-fk-twitter-1 {
          color: #00a4e8;
          line-height: 1.65;
          margin-left: 2px; }
      .cardv2 .image .source .src-instagram {
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 100%;
        background: linear-gradient(87.1deg, #FFD522 -10.92%, #F1000B 48.02%, #B900B3 106.81%); }
        .cardv2 .image .source .src-instagram .icon-fk-instagram-1 {
          color: #ffffff;
          line-height: 1.65;
          margin-left: 1px; }
  .cardv2 .details {
    display: none;
    max-width: 300px;
    margin-top: -24px;
    max-height: 400px;
    cursor: pointer; }
    .cardv2 .details .author {
      margin-bottom: 20px;
      position: relative;
      font-size: 13.5px;
      color: #4c4c4c;
      width: 100%;
      height: 50px;
      background-repeat: no-repeat;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3NiAzMSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5iZ19zb3VyY2VfYmFkZ2VfMTwvdGl0bGU+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik01Ny42NywxMS45NEM1Mi4zNiw2LjcxLDQ4LjQyLDAsMzguMywwSDM3LjdDMjcuNTgsMCwyMy42NCw2LjcxLDE4LjMzLDExLjk0LDE0LjQ3LDE2Ljc2LDYuNjEsMTcuODEsMCwxOFYzMUg3NlYxOEM2OS4zOSwxNy44MSw2MS41MywxNi43Niw1Ny42NywxMS45NFoiLz48L2c+PC9nPjwvc3ZnPg==);
      background-size: 100px; }
      .cardv2 .details .author img {
        position: absolute;
        top: 5px;
        left: 30px; }
      .cardv2 .details .author .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50px; }
      .cardv2 .details .author .name {
        max-width: 150px;
        max-height: 38px;
        padding-top: 30px;
        padding-left: 80px;
        font-weight: 700;
        position: relative;
        font-size: 14px;
        text-align: left;
        overflow: hidden; }
      .cardv2 .details .author .handle {
        max-width: 150px;
        max-height: 15px;
        padding-left: 80px;
        font-size: 11.5px;
        font-weight: 400;
        position: relative;
        text-align: left;
        overflow: hidden; }
      .cardv2 .details .author .created-at {
        position: absolute;
        top: 30px;
        right: 10px;
        color: #9ca5aa;
        font-weight: 400; }
    .cardv2 .details .msg {
      display: none;
      color: #4c4c4c;
      font-size: 14px;
      font-weight: 500;
      padding: 14px;
      line-height: 1.54;
      letter-spacing: 0.56px;
      text-align: left;
      min-height: 100px;
      height: auto;
      overflow: auto;
      margin-top: 10px; }
  .cardv2 .stats {
    display: none;
    border-top: 1px solid #edf2f6;
    width: 280px;
    font-family: CiscoSans;
    color: #b2c0c8;
    font-size: 15px;
    margin-top: 15px;
    padding: 10px;
    position: absolute;
    bottom: 0px; }
    .cardv2 .stats .like-icon-fk {
      margin-left: 20px; }
    .cardv2 .stats .retweet-icon-fk {
      margin-left: 20px; }
